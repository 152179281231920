textarea {
    padding: 0.5rem 1rem;
    scrollbar-width: 0;
    -webkit-focus-ring-color: none;
    color: black;
    font-weight: 400;
    font-size: 16px;
    font-family: 'Inter';
}

textarea:focus,
textarea:focus-visible,
textarea:focus-within,
textarea:active {
    border: 1px solid #888888;
    /* padding: 0.5rem 1rem; */
    outline: 0;
}

/* outline: none !important;
    outline-style: none;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-focus-ring-color: none;
    box-shadow: none !important; */
