.css-selector {
    background: linear-gradient(316deg, #FFFFFF, #252D7C, #FFFFFF);
    background-size: 600% 600%;

    -webkit-animation: AnimationName 6s ease infinite;
    -moz-animation: AnimationName 6s ease infinite;
    animation: AnimationName 6s ease infinite;
}

.dark .css-selector {
    background: linear-gradient(316deg, #222222, #cccccc, #333333);
        background-size: 600% 600%;

        -webkit-animation: AnimationName 6s ease infinite;
        -moz-animation: AnimationName 6s ease infinite;
        animation: AnimationName 6s ease infinite;
}

@-webkit-keyframes AnimationName {
    0% {
        background-position: 4% 0%
    }

    50% {
        background-position: 97% 100%
    }

    100% {
        background-position: 4% 0%
    }
}

@-moz-keyframes AnimationName {
    0% {
        background-position: 4% 0%
    }

    50% {
        background-position: 97% 100%
    }

    100% {
        background-position: 4% 0%
    }
}

@keyframes AnimationName {
    0% {
        background-position: 4% 0%
    }

    50% {
        background-position: 97% 100%
    }

    100% {
        background-position: 4% 0%
    }
}
