.progress {
    font-family: 'Inter', sans-serif;
    color: '#38AF00';
}

.svg {
    display: block;
    width: 100%;
    max-height: 90%;
}

.textdiv {
    display: block;
    transform: scale(1, 1);
}
