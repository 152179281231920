/* Chrome/Opera/Safari */
input::-webkit-input-placeholder {
    color: #888888;
}

/* Firefox 19+ */
input::-moz-placeholder {
    color: #888888;
}

/* IE 10+ */
input:-ms-input-placeholder {
    color: #888888;
}

/* Firefox 18- */
input:-moz-placeholder {
    color: #888888;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input::first-line {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #000000;
    box-shadow: 0 0 0 100px #FFFFFF inset;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 400;
    font-size: -internal-autofill-previewed(16px);
}

.dark input:-webkit-autofill,
.dark input:-webkit-autofill:hover,
.dark input:-webkit-autofill:focus,
.dark input:-webkit-autofill:active,
.dark input::first-line {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #CCCCCC;
    box-shadow: 0 0 0 100px #333333 inset;
    caret-color: #CCCCCC;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 400;
    font-size: -internal-autofill-previewed(16px);
}
